<template>
  <div class="main-view">
    <el-radio-group v-model="table.params.status" class="tabsWrap" @change="handleTabs">
      <el-radio-button :label="1">合作中{{table.enable_total}}</el-radio-button>
      <el-radio-button :label="2">合作结束{{table.disable_total}}</el-radio-button>
    </el-radio-group>
    <div class="section shadow filter__wrap">
      <el-form ref="elFormDom" inline :model="table.params" :size="subUnitSize">
        <el-form-item label="客户名称" prop="name">
          <el-input
            size="mini"
            v-model.trim="table.params.name"
            placeholder="请输入客户名称"
            clearable
            @keydown.enter.native="onSearch"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系电话" prop="phone">
          <el-input
            size="mini"
            v-model.trim="table.params.phone"
            placeholder="请输入联系电话"
            clearable
            @keydown.enter.native="onSearch"
          ></el-input>
        </el-form-item>
        <el-form-item label="账号名称" prop="account_name">
          <el-input
            size="mini"
            v-model.trim="table.params.account_name"
            placeholder="请输入账号名称"
            clearable
            @keydown.enter.native="onSearch"
          ></el-input>
        </el-form-item>
        <el-form-item label="账号号码" prop="account">
          <el-input
            size="mini"
            v-model.trim="table.params.account"
            placeholder="请输入账号号码"
            clearable
            @keydown.enter.native="onSearch"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="onSearch">搜索</el-button>
          <el-button icon="el-icon-refresh-right" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="btnAdd">
        <el-button :size="subUnitSize" type="primary" icon="el-icon-plus" @click="showEdit">创建客户</el-button>
      </div>
    </div>
    
    <VTable
      has-pagionation
      :field="field"
      :loading="table.loading"
      :data="table.data"
      :page='table.params.page'
      :pageSize='table.params.count'
      :total='table.total'
      :tree-props="{children: 'children', hasChildren: 'has_child'}"
      :hasPagionation="true"
      :default-expand-all="true"
      :showSelect="false"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    >
      <template v-slot:sorter_str="{row}">
        <div v-if="row.sorter.length > 0">
          <span v-for="(item,index) in row.sorter" :key="index">
            {{item}}
            <span v-if="index < row.sorter.length - 1">、</span>
          </span>
        </div>
      </template>
      <template v-slot:open_price="{row}">
        <el-switch
          v-model="row.open_price"
          :active-value="1"
          :inactive-value="2"
          @change="handleStatus(row)">
        </el-switch>
      </template>
      <template v-slot:discount_type="{row}">
        <span>{{toStr({1:'按商品分类',2:'按商品名称'},row.discount_type)}}</span>
      </template>
      <template v-slot:order_type="{row}">
        <span>{{toStr({1:'线下结算',2:'线上支付'},row.order_type)}}</span>
      </template>
      <template v-slot:action="{row}">
        <el-button type="text" @click="showDetail(row,1)">编辑食堂</el-button>
        <el-button type="text" @click="showDetail(row,2)">编辑账号</el-button>
        <!-- 折扣率按商品名称的没有折扣体系操作 -->
        <el-button v-if="row.discount_type == 1" type="text" @click="showRebate(row)">折扣体系</el-button>
        <!-- 如果是按商品名称设置折扣率的  操作就没有了折扣体系，需要把使用采购价按钮拿到客户列表的操作中来设置 -->
        <el-button v-if="row.discount_type == 2" type="text" @click="handlePrice(row)" :class="row.price_type == 1 ? 'greenColor' : 'redColor'">{{row.price_type == 1 ? '使用采购价' : '关闭采购价'}}</el-button>
        <el-dropdown @command="handleCommand($event,row)" style="margin-left:10px">
          <span class="el-dropdown-link">
            更多<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="edit">编辑客户</el-dropdown-item>
            <el-dropdown-item command="option">{{row.status == 1 ? '禁用' : '启用'}}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
    </VTable>

    <!-- 编辑客戶 -->
    <edit ref="edit" @refresh="getTable"></edit>

    <!-- 编辑食堂，编辑账号 -->
    <detail ref="detail" @refresh="getTable"></detail>

    <!-- 折扣体系 -->
    <Rebate ref="rebate" @refresh="getTable"></Rebate>

  </div>
</template>

<script>
import VTable from '@/components/VTable';
import Edit from './components/Edit.vue'
import Detail from './components/Detail.vue'
import Rebate from './components/Rebate.vue'
import { mixinTable } from '@/mixins/table.js'
import {getStorage} from '@/storage'

export default {
  name: 'UserMerchant',
  components: {
    Edit,
    Detail,
    Rebate,
    VTable,
  },
  mixins: [mixinTable],
  data() {
    return {
      open_pick: getStorage('open_pick') || 1,  // 是否开启分拣功能：1-开启；2-关闭
      subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
      field: [
        { name: "id", label: "ID", width:'80', hidden: false },
        { name: "name", label: "客户名称", hidden: false},
        { name: "main_account", label: "负责人姓名", hidden: false},
        { name: "phone", label: "联系电话", width:'120', hidden: false},
        { name: "store_num", label: "食堂数量", hidden: false},
        { name: "account_count", label: "账号数量", hidden: false},
        { name: "spec_count", label: "报价数量", hidden: false},
        { name: "order_count", label: "下单次数",hidden: false },
        { name: "open_price", label: "订单改价",hidden: false },
        { name: "discount_type", label: "折扣率",width:'100',hidden: false },
        { name: "order_type", label: "下单方式",hidden: false },
        { name: "auth_time", label: "创建时间",width:'140',hidden: false },
        { name: "sorter_str", label: "分拣员",hidden: false },
        { name: "action", label: "操作",width: "290", fixed:"right", hidden: false }
      ],
      table: {
        loading: false,
        params: {
          status: 1,
          name: '',
          phone: '',
          account_name: '',
          account: '',
          type: 1,  // 类型 1:列表 2:参数
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
        enable_total: 0,
        disable_total: 0,
      },
    }
  },
  created() {
    this.getTable();
  },
  mounted() {
    // 未开启分拣功能则隐藏分拣员字段
    if(this.open_pick == 2) {
      this.field.splice(-2, 1); // 删除倒数第2项 (分拣员)
    }
  },
  methods: {
    getTable() {
      this.table.loading = true;
      this.$http.get('/admin/group/merchantList', {params:this.table.params} ).then(res => {
        if(res.code === 1) {
          this.table.data = res.data.list;
          this.table.total = res.data.total;
          this.table.enable_total = res.data.enable_total;
          this.table.disable_total = res.data.disable_total;
        }
      }).finally(() => {
        this.table.loading = false;
      })
    },
    // 新增、编辑
    showEdit(row) {
      let dom = this.$refs.edit
      dom.toggle(true)
      dom.getDetail(row)
      dom = null
    },
    // 编辑食堂，编辑账号
    showDetail(row,type) {
      let dom = this.$refs.detail;
      dom.toggle(true)
      dom.getDetail(row,type)
      dom = null
    },
    // 订单改价开启，关闭
    handleStatus(row) {
      this.$http.post('/admin/group/openPrice', {id: row.id}).then(res => {
        if(res.code == 1) {
          this.$message.success(`${row.open_price == 1 ? '开启' : '关闭'}成功！`)
        } else {
          this.$message.error(res.msg)
        }
      }).finally(() => {
        this.getTable()
      })
    },
    // 折扣体系
    showRebate(row) {
      let dom = this.$refs.rebate
      dom.toggle(true)
      if(!!row.id) {
        dom.getDetail(row)
      }
      dom = null
    },
    handleCommand(value,row) {
      if(value == 'edit') {  // 编辑客户
        this.showEdit(row)
      } else if(value == 'option') { // 禁用/启用
        this.handleOption(row);
      }
    },
    handleOption(row) {
      let str = row.status == 1 ? '确认禁用后，该客户名下账号将无法登录壹号净菜小程序客户端，系统也将无法为该客户下单您确认禁用吗？' : '确认恢复启用吗？'
      this.$confirm(str,`${row.status == 1 ? '禁用提示' : '启用提示'}`, {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        this.$http.post('/admin/group/updateMerchantStatus', {id: row.id,status: row.status == 1 ? 2 : 1}).then(res => {
          if(res.code == 1) {
            this.$message.success(`${row.status == 1 ? '禁用' : '启用'}成功！`)
          } else {
            this.$message.error(res.msg)
          }
        }).finally(() => {
          this.getTable()
        })
      }).catch(() => {})
    },
    handlePrice(row) {
      // price_type定价方式：1-设置参考价；2-使用采购价
      let str = row.price_type == 1 ? '使用采购价将会自动更新商品规格售价，以最新采购价作为本客户的参考价，您确认开启吗?' : '确认关闭采购价操作？'
      this.$confirm(str,'', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.post('/admin/group/setPriceType', {id: row.group_id, price_type: row.price_type == 1 ? 2 : 1}).then(res => {
          if(res.code == 1) {
            this.$message.success('操作成功')
            this.getTable();
          } else {
            this.$message.error(res.msg)
          }
        })
      }).catch(() => {})
    }
    
  }
}
</script>
<style scoped>
.el-dropdown-link {
  cursor: pointer;
  color: #2cb167;
  font-size: 12px;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.redColor {
  color: #e6a23c;
}
.greenColor {
  color: #2cb167;
}
</style>

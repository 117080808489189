<template>
  <el-dialog
    append-to-body
    width="800px"
    :title="currType == 1 ? '食堂信息' : '账号信息'"
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    >
    <el-button :size="subUnitSize" type="primary" icon="el-icon-plus" style="margin-bottom:10px;" @click="handleEdit">{{currType == 1 ? '创建食堂' : '创建账号'}}</el-button>
    <!-- 食堂列表 -->
    <el-table
      v-if="currType == 1"
      :data="tableData"
      border
      style="width: 100%">
      <el-table-column
        prop="name"
        label="食堂名称">
      </el-table-column>
      <el-table-column
        prop="province_str"
        label="食堂地址">
        <template slot-scope="{row}">
          <span>{{row.province_str}}{{row.city_str}}{{row.area_str}}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="address"
        label="详细地址">
      </el-table-column>
      <el-table-column
        prop="create_time"
        label="创建时间">
      </el-table-column>
      <el-table-column
        fixed="right"
        label="操作"
        width="90">
        <template slot-scope="{row}">
          <el-button type="text" size="small" @click="handleEdit(row)">编辑</el-button>
          <el-button type="text" size="small" @click="remove(row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 账号列表 -->
    <el-table
      v-if="currType == 2"
      :data="tableData"
      border
      style="width: 100%">
      <el-table-column
        prop="name"
        label="姓名">
      </el-table-column>
      <el-table-column
        prop="account"
        label="电话">
      </el-table-column>
      <el-table-column
        prop="type"
        label="角色"
        width="100px">
        <template slot-scope="{row}">
          <span>{{toStr({1:'超级管理员',2:'管理员',3:'员工'},row.type)}}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="address_name"
        label="食堂">
        <template slot-scope="{row}">
          <div v-for="(item,index) in row.address_name" :key="index">
            <span>{{item}}</span>
            <span v-if="index < row.address_name.length - 1">、</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="create_time"
        label="时间">
      </el-table-column>
      <el-table-column
        fixed="right"
        label="操作"
        width="150">
        <template slot-scope="{row}">
          <el-button v-if="currType == 2" type="text" size="small" @click="handlePwd(row)">修改密码</el-button>
          <el-button type="text" size="small" @click="handleEdit(row)">编辑</el-button>
          <el-button type="text" size="small" @click="remove(row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <template #footer>
      <el-row type="flex" justify="end">
        <el-button @click='toggle(false)'>关闭</el-button>
        <!-- <el-button type="primary" :loading="form.loading" :disabled="form.loading" @click='confirm'>确定</el-button> -->
      </el-row>
    </template>

    <!-- 编辑食堂 -->
    <el-dialog
      width="800px"
      title="编辑食堂"
      :visible.sync="innerVisibleStore"
      :before-close="closeForm"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      append-to-body>
      <el-form
        class="staff-from"
        v-loading="loading"
        element-loading-text="加载中…"
        ref='elFormDom'
        label-width='100px'
        label-position='right'
        :model='form.data'
        :rules='form.rules'
        :size="subUnitSize"
      >
        <el-form-item label="食堂名称" prop="store_name" style="width:400px;">
          <el-input
            size="mini"
            v-model.trim="form.data.store_name"
            placeholder="请输入"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="食堂地址" prop="area" style="width:400px;">
          <el-cascader
            v-model="form.data.area"
            :options="areaArr"
            :props="{ checkStrictly: false }"
            clearable
            filterable
            style="width:100%;">
          </el-cascader>
        </el-form-item>
        <el-form-item label="详细地址" prop="dropAddress">
          <el-autocomplete
            v-model="form.data.dropAddress"
            :fetch-suggestions="querySearchAsync"
            placeholder="请输入关键词"
            @select="change"
            style="width:100%;"
          ></el-autocomplete>
          <div class="map" id="mapDiv"></div>
        </el-form-item>
        <el-form-item label="门牌号" prop="sup_address" style="width:400px;">
          <el-input
            size="mini"
            v-model.trim="form.data.sup_address"
            placeholder="门牌号"
            clearable
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeForm">取 消</el-button>
        <el-button type="primary" @click="confirm">确定</el-button>
      </div>
    </el-dialog>

    <!-- 编辑账号 -->
    <el-dialog
      width="800px"
      title="编辑账号"
      :visible.sync="innerVisibleAccount"
      :before-close="closeForm"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      append-to-body>
      <el-form
        class="staff-from"
        v-loading="loading"
        element-loading-text="加载中…"
        ref='elFormDom'
        label-width='100px'
        label-position='right'
        :model='accountForm.data'
        :rules='accountForm.rules'
        :size="subUnitSize"
      >
        <el-form-item label="姓名" prop="account_name" key="account_name">
          <el-input
            size="mini"
            v-model.trim="accountForm.data.account_name"
            placeholder="请输入"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="手机号码" prop="account" key="account">
          <el-input
            size="mini"
            v-model.trim="accountForm.data.account"
            placeholder="请输入"
            maxlength="11"
            clearable
            :disabled="isChange"
          ></el-input>
        </el-form-item>
        
        
        <el-form-item label="角色" prop="type" key="type">
          <el-radio-group v-model="accountForm.data.type" @change="changeType">
            <el-radio :label="1">超级管理员</el-radio>
            <el-radio :label="2">管理员</el-radio>
            <el-radio :label="3">员工</el-radio>
          </el-radio-group>
        </el-form-item>
        <!-- 超级管理员不需要选食堂,管理员可以负责多个食堂，员工角色只能负责一个食堂 -->
        <el-form-item label="食堂地址" prop="address_id" key="address_id" v-if="accountForm.data.type == 2">
          <el-select v-model="accountForm.data.address_id" multiple filterable >
            <el-option
              v-for="item in storeArr"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="食堂地址" prop="value" key="value" v-if="accountForm.data.type == 3">
          <el-select v-model="accountForm.data.value" filterable >
            <el-option
              v-for="item in storeArr"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeForm">取 消</el-button>
        <el-button type="primary" @click="confirm">确定</el-button>
      </div>
    </el-dialog>

    <!-- 修改密码 -->
    <NewPwd ref="newpwd"></NewPwd>
  </el-dialog>
</template>

<script>
  import NewPwd from './newPwd.vue'
  import { getStorage } from '@/storage'
  import { mixinTable } from '@/mixins/table.js'
  
  export default {
    name: 'UserMerchantDetail',
    mixins: [mixinTable],
    components: {
      NewPwd
    },
    data() {
      return {
        token: getStorage('token'),
        subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
        visible: false,
        isChange: false,
        loading: false,
        merchant_id: '', // 客户id
        form: {
          data: {
            store_id: '', // 食堂id
            store_name: '', // 食堂名称
            area: [],// 食堂地址
            dropAddress: '', // 地址
            longitude: '',// 经度
            latitude: '', // 维度
            sup_address: '',// 门牌号
          },
          rules: {
            area: [{ required: true, message:'必填项', trigger: 'change'}],
            store_name: [{ required: true, message:'必填项', trigger: 'bule'}],
            dropAddress: [{ required: true, message:'必填项', trigger: 'bule'}],
          }
        },
        accountForm: {
          loading: false,
          data: {
            account_name: '', // 账号姓名
            account_id: '', // 账号id
            account: '', // 账户
            type: 1, //角色 1:管理员 2:普工管理员，3：员工
            address_id: [],// 食堂id多选
            value: '',// 食堂id单选
          },
          rules: {
            account_name: [{ required: true, message:'必填项', trigger: 'blur'}],
            account: [{ required: true, message:'必填项', trigger: 'blur'}],
            type: [{ required: true, message:'必填项', trigger: 'change'}],
            address_id: [{ required: true, message:'必填项', trigger: 'change'}],
            value: [{ required: true, message:'必填项', trigger: 'change'}],
          }
        },
        isChecked: true,
        areaArr: [],

        innerVisibleStore: false,
        innerVisibleAccount: false,
        currType: '',
        storeArr: [],
        storeArr2: [],
        tableData: [],

        zoom: 12,
        location: {lng: 0, lat: 0},
        map: null,
        localsearch: null,
        polygonPath: [],

        btnloading: false,
        addUrl: "",
        options1: [],
      }
    },
    methods: {
      getDetail(row, currType) {
        console.log(currType,"currType")
        this.tableData = [];
        Object.assign(this.form.data, row)
        this.merchant_id = row.id; // 客户id
        this.getArea();
        this.currType = currType; // 1：食堂，2：账号
        if(currType == 1) {
          this.getStore(); // 获取该商户下的食堂
        } else {
          this.getStore(); // 获取该商户下的食堂
          this.getAccount(); // 获取该商户下的账号
        }
      },
      reset(done) {
        this.isChange = false;
        this.$emit('refresh')
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      // 获取全国省市区
      getArea() {
        this.$http.post('/common/district/all', {is_all:1}).then(res => {
          if(res.code === 1) {
            this.areaArr = res.data.list;
          }
        })
      },
      // 根据商户id获取商户下的食堂
      getStore() {
        this.$http.get('/admin/path/storeList', { params:{ page: 1, count: 10000, merchant_id: this.merchant_id}}).then(res => {
          if(res.code == 1) {
            if(this.currType == 1) { // 食堂列表取值
              this.tableData = res.data.list;
            } else {
              this.storeArr = res.data.list;
              this.storeArr2 = res.data.list;
            }
          }
        })
      },
      // 根据商户id获取商户下的账号
      getAccount() {
        this.$http.get('/admin/group/accountList', { params:{ page: 1, count: 10000, merchant_id: this.merchant_id}}).then(res => {
          if(res.code == 1) {
            this.tableData = res.data.list;
          }
        })
      },
      closeForm() {
        this.isChange = false;
        this.innerVisibleAccount = false;
        this.innerVisibleStore = false;
        this.accountForm.data = {
          account_name: '', // 账号姓名
          account_id: '', // 账号id
          account: '', // 账户
          type: 1, //角色 1:管理员 2:普工管理员，3：员工
          address_id: [],// 食堂id多选
          value: '',// 食堂id单选
        }
        this.$refs.elFormDom.resetFields()
        this.$refs.elFormDom.clearValidate();//移除该表单项的校验结果
      },
      // 修改密码
      handlePwd(row) {
        let dom = this.$refs.newpwd
        dom.toggle(true)
        dom.getDetail(row)
        dom = null
      },
      changeType() {
        console.log(222)
        this.accountForm.data.address_id = [];
        this.accountForm.data.value = '';
      },
      // 提交
      confirm() {
        console.log(this.merchant_id)
        this.$refs.elFormDom.validate(valid => {
          if (valid) {
            var _params = {};
            var url = '';
             // 管理员角色可以多选食堂，员工角色只能负责一个食堂
            var _address_id = this.accountForm.data.type == 2 ? this.accountForm.data.address_id.join("|") : this.accountForm.data.value;
            // currType:1食堂，2账号
            if(this.currType == 1 && !!this.isChange) { // 编辑食堂
              url = '/admin/merchant/addStore';
              const item = {
                id: this.form.data.store_id,// 食堂id,新增时传0
                name: this.form.data.store_name,
                province: this.form.data.area[0],
                city: this.form.data.area[1],
                area: this.form.data.area[2],
                address: this.form.data.dropAddress,
                sup_address: this.form.data.sup_address,
                lng: this.form.data.longitude,
                lat: this.form.data.latitude,
              };
              const _store = [];
              this.tableData.map(v => {
                if(v.id == item.id) {
                  _store.push(item);
                }else{
                  _store.push(v);
                }  
              })
              _params = {
                id: this.merchant_id, // 客户id
                store: _store
              };
            } else if(this.currType == 1 && !this.isChange) { // 添加食堂
              url = '/admin/merchant/insertStore'
              _params = {
                merchant_id: this.merchant_id, //客户id
                name: this.form.data.store_name,
                province: this.form.data.area[0],
                city: this.form.data.area[1],
                area: this.form.data.area[2],
                address: this.form.data.dropAddress,
                sup_address: this.form.data.sup_address,
                lng: this.form.data.longitude,
                lat: this.form.data.latitude,
              }
            } else if(this.currType == 2 && !!this.isChange) {//编辑账号
              url = '/admin/merchant/editAccount'
              const item = {
                id: this.accountForm.data.account_id,// 账号id
                name: this.accountForm.data.account_name,
                account: this.accountForm.data.account,
                type: this.accountForm.data.type,
                address_id: _address_id,
              }
              const _account = [];
              this.tableData.map(v => {
                if(v.id == item.id) {
                  _account.push(item);
                }else{
                  _account.push(v);
                }  
              })
              _params = {
                id: this.merchant_id, // 商户id
                account: _account
              };
            } else if(this.currType == 2 && !this.isChange) { // 新增账号
              url = '/admin/merchant/addAccount'
              _params = {
                merchant_id: this.merchant_id, //商户id
                name: this.accountForm.data.account_name,
                account: this.accountForm.data.account,
                type: this.accountForm.data.type,
                address_id: _address_id,
              }
            }
            this.form.loading = true;
            this.$http.post(url, _params).then(res => {
              if(res.code === 1) {
                this.closeForm();
                if(this.currType == 1) {
                  this.innerVisibleStore = false; // 关闭弹窗
                  this.getStore() // 重新获取食堂列表
                }
                if(this.currType == 2) {
                  this.innerVisibleAccount = false;
                  this.getAccount() // 重新获取账号列表
                }
                // this.toggle(false);
                // this.$emit('refresh')
              }
            }).finally(() => {
              this.form.loading = false;
            })
          }
        })
      },
      handleEdit(row) {
        if(this.currType == 1) {
          this.innerVisibleStore = true;
          this.tobmap();
          this.form.data = {
            store_id: '', // 食堂id
            store_name: '', // 食堂名称
            area: [],// 食堂地址
            dropAddress: '', // 地址
            longitude: '',// 经度
            latitude: '', // 维度
            sup_address: '',// 门牌号
          }
        } else {
          this.innerVisibleAccount = true;
        }
        
        if(!!row.id) {
          this.isChange = true;
          if(this.currType == 1) { // 食堂
            this.form.data.store_id = row.id; // 食堂id
            this.form.data.store_name = row.name; // 食堂名称
            this.form.data.dropAddress = row.address; // 食堂地址
            this.form.data.longitude = row.lng; // 经度
            this.form.data.latitude = row.lat; // 维度
            this.form.data.sup_address = row.sup_address; // 食堂门牌号
            this.form.data.area = [row.province,row.city,row.area];
          } else if(this.currType == 2) { // 账号
            this.accountForm.data.account_id = row.id; // 账号id
            this.accountForm.data.account_name = row.name;
            this.accountForm.data.account = row.account;
            this.accountForm.data.type = row.type;
            if(row.type == 2) {// 管理员角色
              this.accountForm.data.address_id =  !!row.address_id ? row.address_id.split('|').map(v => Number(v)) : [];
            } else {
              this.accountForm.data.value = Number(row.address_id);
            }
          }
        }
      },
      // 删除
      remove(row) {
        this.$confirm("请确认删除？","提示",{
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          // currType:1:食堂，2：账号
          let url = this.currType == 1 ? '/admin/merchant/delStore' : '/admin/merchant/delAccount'
          this.$http.post(url, {id: row.id}).then(res => {
            if(res.code == 1) {
              this.$message.success("操作成功！")
              this.tableData.forEach((v,i) => {
                if(v.id == row.id) {
                  this.tableData.splice(i,1)
                }
              })
            }
          })
        }).catch(() => {})
      },
      tobmap() {
        console.log(this.map)
        !this.map &&
          !this.localsearch &&
          setTimeout(() => {
            this.map = new T.Map("mapDiv");
            //设置显示地图的中心点和级别
            this.map.centerAndZoom(
              new T.LngLat(
                this.form.data.longitude || 115.86086,
                this.form.data.latitude || 28.691483
              ),
              12
            );
            
            //创建面对象
            this.map.disableDoubleClickZoom();
            this.map.enableDrag();
            var config = {
              pageCapacity: 10, //每页显示的数量
              onSearchComplete: (result) => {
                //根据返回类型解析搜索结果
                console.log(result.getResultType());
                switch (parseInt(result.getResultType())) {
                  case 1:
                    //解析点数据结果
                    if (result.getPois()) {
                      this.options1 = result.getPois().map((item) => {
                        return {
                          value: item.name,
                          address: item.address,
                          lonlat: item.lonlat,
                        };
                      });
                    } else {
                      this.options1 = [];
                    }
                    break;
                }
              },
            };
            this.map.addEventListener("click", (res) => {
              console.log(res)
              console.log("-------------------")
              this.form.data.latitude = res.lnglat.lat;
              this.form.data.longitude = res.lnglat.lng;
              this.map.clearOverLays();
              let marker = new T.Marker(
                new T.LngLat(res.lnglat.lng, res.lnglat.lat)
              );
              //向地图上添加标注
              this.map.addOverLay(marker);
              
            });
            //创建搜索对象
            this.localsearch = new T.LocalSearch(this.map, config);
            //创建标注对象
            let marker = new T.Marker(
              new T.LngLat(
                this.form.data.longitude || 115.86086,
                this.form.data.latitude || 28.691483
              )
            );
            //向地图上添加标注
            this.map.addOverLay(marker);
            this.confirmAddress(this.form.data.address);
          }, 500);
        if (this.map && this.localsearch) {
          setTimeout(() => {
            this.map.centerAndZoom(
              new T.LngLat(
                this.form.data.longitude || 115.86086,
                this.form.data.latitude || 28.691483
              ),
              12
            );
            this.map.clearOverLays();
            let marker = new T.Marker(
              new T.LngLat(
                this.form.data.longitude || 115.86086,
                this.form.data.latitude || 28.691483
              )
            );
            //向地图上添加标注
            this.map.addOverLay(marker);
          }, 500);
        }
      },
      change(e) {
        console.log(e)
        console.log("**************")
        if (e) {
          let Lng = e.lonlat.split(",")[0] * 1;
          let Lat = e.lonlat.split(",")[1] * 1;
          this.map.clearOverLays();
          this.map.panTo(new T.LngLat(Lng, Lat));
          //创建标注对象
          let marker = new T.Marker(new T.LngLat(Lng, Lat));
          //向地图上添加标注
          this.map.addOverLay(marker);
          this.form.data.latitude = Lat;
          this.form.data.longitude = Lng;
          this.form.data.dropAddress = e.address+e.value;
        }
      },
      // 搜索框的搜索事件
      confirmAddress(query) {
        this.localsearch && query && this.localsearch.search(query, 7);
      },
      querySearchAsync(queryString, cb) {
        console.log(queryString, "queryString", this);
        this.confirmAddress(queryString);
        setTimeout(() => {
          cb(this.options1);
        }, 500);
      },
      updatePolygonPath(e) {
        this.polygonPath = e.target.getPath();
      },
    },
    beforeDestroy() {
      if (this.map) {
        this.map.destroy(); // 销毁天地图对象
        this.map = null; // 重置为null
      }
    }
  }
</script>

<style scoped lang="scss">
.addPerson__wrap {
  @include position($t: 0, $r: 0, $b: 0, $l: 0);
}

.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
  }
  .avatar {
    width: 100px;
    height: 100px;
    display: block;
  }
  // /* .idPhoto .avatar{
  //   width: 100% !important;
  // } */
  .idPhoto .avatar-uploader-icon {
    width: 100px !important;
    height: 100px !important;
    line-height: 100px !important;
    border: 1px dashed #d9d9d9;
  }
  .map {
    height: 400px;
    width: 100%;
  }
</style>
